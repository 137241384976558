// extracted by mini-css-extract-plugin
export var blog_post = "post-module--blog_post--12Bps";
export var disqus = "post-module--disqus--3vIgv";
export var gatsbyCodeTitle = "post-module--gatsby-code-title--3JzxA";
export var gatsbyHighlight = "post-module--gatsby-highlight--2v0x1";
export var gatsbyHighlightCodeLine = "post-module--gatsby-highlight-code-line--1Q1HA";
export var lineNumbers = "post-module--line-numbers--7obcQ";
export var lineNumbersRows = "post-module--line-numbers-rows--3x4OB";
export var person_who_wrote = "post-module--person_who_wrote--36Pcj";
export var post_toc = "post-module--post_toc--eX7-R";
export var prev_next = "post-module--prev_next--2AShg";
export var prev_next_ul = "post-module--prev_next_ul--4PgGV";
export var related_post = "post-module--related_post--3WFRt";
export var root = "post-module--root--3wKMV";
export var sns_share = "post-module--sns_share--7wYRo";