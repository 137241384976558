import React from 'react'
import { graphql } from 'gatsby'
import PostLayout from '@/layouts/post'
import { PostPageContext } from '@gatsby-node/createPostPages'
import { PostToc, SNSShare, PrevNext, GoTop, SEO, PersonWhoWrote } from '@/components/molecules'
import { BlogPost, RelatedPostCardList } from '@/components/organisms'
import { useLocation } from '@reach/router'

import { humanize, machinize } from '@/utils'
import { useHandleResponsible } from '@/hooks'
import Disqus from 'gatsby-plugin-disqus'
import AppConfig from '@/config/app'

// Explicitly import to prevent purge CSS
import '@styles/css/highlight.scss'
import * as styles from './post.module.scss'

const makeBredcrumbItems = (category: string) => {
  const items = [
    {
      name: 'ホーム',
      link: '/',
      icon: 'home',
    },
    {
      name: humanize(category),
      link: `/category/${machinize(category)}`,
    },
  ]
  return items
}

type Props = {
  pageContext: PostPageContext
}

const Component: React.FC<Props> = ({ data, pageContext }) => {
  const headings = data.headings.headingsDetail
  const node = data.post.edges[0].node
  const f = node.frontmatter
  const location = useLocation()
  const href = location.href
  const breadcrumbs = makeBredcrumbItems(f.category)
  const { relatedPosts, prevNextProps } = pageContext
  const thumbnailPath = f.image.childImageSharp.fluid.src

  let { profile, blog } = AppConfig(location)
  profile['backgroundImg'] = data.profileBackgroundImg

  const post = {
    title: f.title,
    description: node.excerpt,
    url: href,
    imageUrl: f.image.publicURL,
    createdOn: f.createdOn,
    updatedOn: f.updatedOn
  }

  const { changeDesign, snsIconSize, goTopSize } = useHandleResponsible({ breakPoint: 1160 })

  return (
    <PostLayout
      snsShare={<SNSShare title={f.title} link={href} />}
      toc={<PostToc className={styles.post_toc} location={location} headings={headings} />}
    >
      <SEO type={"article"} pageType={"post"} post={post} thumbnailPath={thumbnailPath} />
      <div className={styles.root}>
        <BlogPost className={styles.blog_post} node={node} breadcrumbs={breadcrumbs} />
        <PersonWhoWrote className={styles.person_who_wrote} profile={profile} blog={blog} />
        <RelatedPostCardList className={styles.related_post} edges={relatedPosts} />
        <PrevNext className={styles.prev_next} ulClassName={styles.prev_next_ul} {...prevNextProps} />
        <div className={styles.disqus}>
          {process.env.GATSBY_DISQUS_SHORTNAME && (
            <Disqus
              identifier={node.id}
              title={node.frontmatter.title}
              url={href}
            />
          )}
        </div>
      </div>
      <GoTop size={goTopSize} >
        {changeDesign && <SNSShare overrideClassName={styles.sns_share} size={snsIconSize > 40 ? 40 : snsIconSize} title={f.title} link={href} />}
      </GoTop>
    </PostLayout>
  )
}

export const PostTemplateQuery = graphql`
  query ($slug: String!) {
          post: allMarkdownRemark(
      filter: {fields: {slug: {eq: $slug}}}
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            createdOn
            updatedOn
            tags
            category
            subcategory
            image {
              publicURL
              childImageSharp {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    },
    headings: markdownRemark(fields: {slug: {eq: $slug }}) {
      headingsDetail {
        id
        value
        depth
        parents {
          id
          value
          depth
        }
      }
    },
    profileBackgroundImg: file(relativePath: { eq: "profile-cover.png"}) {
      publicURL
      childImageSharp{
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default Component
